





















import { Component, Vue, Prop } from "vue-property-decorator";
import MainHeader from "@/components/main-header/main-header.vue";
import NavLeft from "@/components/nav-left/nav-left.vue";
import CommonJuese from "../components/common-juese.vue";
@Component({
  components: {
    MainHeader,
    NavLeft,
    CommonJuese,
  },
})
export default class Name extends Vue {
  private pageSetting: any = {};
  private mounted() {
    const setting = this.$store.state.configuration["用户权限管理"].child;
    setting.map((item: any, index: any) => {
      if (item.name === "角色管理") {
        this.pageSetting = item;
      }
    });
  }
}
